// TitleArea.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import from 'react-router-dom'
import Title from './Title';
import Subtitle from './Subtitle';
import TitleImage from './TitleImage';
import ButtonItem from '../button/ButtonItem';
import './TitleArea.scss';

const TitleArea = ({ title, subtitle, imageUrl, buttonText, buttonLink }) => {
  const navigate = useNavigate(); // Hook for navigation

  const handleButtonClick = () => {
    navigate(buttonLink); // Navigate to the link provided by the button
  };

  return (
    <section className="title-area">
      <div className="text-container">
        <Title text={title} />
        <Subtitle text={subtitle} />
        <ButtonItem text={buttonText} highlighted onClick={handleButtonClick} />
      </div>
      <TitleImage url={imageUrl} alt={subtitle} />
    </section>
  );
};

export default TitleArea;
