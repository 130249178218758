import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './About.scss';

const About = () => {
  const [aboutContent, setAboutContent] = useState({ main_content: [] });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/about`)
      .then(response => {
        setAboutContent(response.data.data.attributes);
      })
      .catch(error => console.log(error));
  }, []);

  const renderRichText = (nodes) => {
    return nodes.map((node, index) => {
      if (node.type === 'paragraph') {
        return <p key={index}>{renderRichText(node.children)}</p>;
      } else if (node.type === 'link') {
        return <a key={index} href={node.url} target="_blank" rel="noopener noreferrer">{renderRichText(node.children)}</a>;
      } else if (node.type === 'text') {
        return node.bold ? <strong key={index}>{node.text}</strong> : node.text;
      } else if (node.type === 'heading' && node.level === 4) {
        return <h4 key={index}>{renderRichText(node.children)}</h4>;
      } else {
        return null; // For simplicity, we ignore other types, but you can add more cases as needed
      }
    });
  };

  return (
    <div className="about-page">
      <h1>{aboutContent.Title}</h1>
      <div className="about-content">
        {renderRichText(aboutContent.main_content)}
      </div>
    </div>
  );
};

export default About;
