// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ButtonItem.css */
.button-item {
  padding: 12px 24px;
  background-color: #000; /* Assuming black button background */
  color: #fff; /* Assuming white button text color */
  border: none;
  cursor: pointer;
  font-size: 16px; /* As per your design */
  border-radius: 4px; /* Assuming rounded corners */
  font-weight: bold; /* If the button text is bold */
  text-transform: uppercase; /* If the button text is uppercase */
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
}

.button-item-highlighted {
  /* Additional styles for highlighted state if any */
}

.button-item:hover {
  background-color: #333; /* Darker background on hover */
}`, "",{"version":3,"sources":["webpack://./src/stories/components/button/ButtonItem.scss"],"names":[],"mappings":"AAAA,mBAAA;AACA;EACE,kBAAA;EACA,sBAAA,EAAA,qCAAA;EACA,WAAA,EAAA,qCAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA,EAAA,uBAAA;EACA,kBAAA,EAAA,6BAAA;EACA,iBAAA,EAAA,+BAAA;EACA,yBAAA,EAAA,oCAAA;EACA,sCAAA,EAAA,gDAAA;AACF;;AAEA;EACE,mDAAA;AACF;;AAEA;EACE,sBAAA,EAAA,+BAAA;AACF","sourcesContent":["/* ButtonItem.css */\n.button-item {\n  padding: 12px 24px;\n  background-color: #000; /* Assuming black button background */\n  color: #fff; /* Assuming white button text color */\n  border: none;\n  cursor: pointer;\n  font-size: 16px; /* As per your design */\n  border-radius: 4px; /* Assuming rounded corners */\n  font-weight: bold; /* If the button text is bold */\n  text-transform: uppercase; /* If the button text is uppercase */\n  transition: background-color 0.3s ease; /* Smooth background color transition on hover */\n}\n\n.button-item-highlighted {\n  /* Additional styles for highlighted state if any */\n}\n\n.button-item:hover {\n  background-color: #333; /* Darker background on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
