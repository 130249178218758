// MenuItem.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './MenuItem.scss'; // CSS for the MenuItem

const MenuItem = ({ text, path, highlighted = false, onClick }) => (
  // Use Link instead of button, applying the same className logic
  <Link 
    to={path}
    className={`menu-item ${highlighted ? 'menu-item-highlighted' : ''}`}
    onClick={onClick}
  >
    {text}
  </Link>
);

export default MenuItem;
