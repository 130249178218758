import React from 'react';
import PropTypes from 'prop-types'; 
import './SocialMediaIcons.scss';

const SocialMediaIcons = ({ icons }) => {
  return (
    <div className="social-media-icons">
      {icons.map((icon, index) => (
        <a key={index} href={icon.url} className="social-media-link" target="_blank" rel="noopener noreferrer">
          {/* Access the nested URL for the image */}
          <img src={icon.icon.data.attributes.url} alt={icon.Name} />
        </a>
      ))}
    </div>
  );
};

SocialMediaIcons.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        data: PropTypes.shape({
          attributes: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
      url: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired, // Changed to match the structure you provided
    })
  ).isRequired,
};

export default SocialMediaIcons;
