// BlogList.jsx
import React, { useEffect, useState } from 'react';
import axios from '../../../services/axiosConfig';
import BlogCardContainer from './BlogCardContainer.jsx';
//import '../blog/blogList.scss'; // You might need to create this SCSS file

const BlogList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // No limit here, since we want to fetch all posts
        const response = await axios.get('/api/blog-articles?populate=Images');
        setPosts(response.data.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div>
      <section className="blog-list-section">
        <BlogCardContainer posts={posts} />
      </section>
    </div>
  );
};

export default BlogList;
