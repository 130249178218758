// Logo.jsx
import React from 'react';
import { Link } from 'react-router-dom'; // Ensure to import Link from react-router-dom
import '../../styles.scss';

const Logo = ({ logoImage, logoText }) => {
  return (
    <div className="logo">
      {logoImage ? (
        <img src={logoImage} alt={logoText} />
      ) : (
        // Wrap the h1 or text in a Link to make it clickable
        <Link to="/" className="logo-text-link">
          <h1>{logoText}</h1>
        </Link>
      )}
    </div>
  );
};

export default Logo;
