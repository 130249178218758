import React, { useEffect, useState } from 'react';
import Logo from '../logo/Logo';
import SocialMediaIcons from '../SocialMediaIcons';
import LinkColumn from '../LinkColumn';
import './Footer.scss';
import axios from '../../../services/axiosConfig'; // Import the Axios instance

const Footer = () => {
  const [footerMessage, setFooterMessage] = useState('');
  const [linkColumns, setLinkColumns] = useState([]);
  const [socialMediaIcons, setSocialMediaIcons] = useState([]);

  useEffect(() => {
    // Fetch the footer message
    const fetchFooterContent = async () => {
      try {
        const [messageResponse, linksResponse] = await Promise.all([
          axios.get('/api/footer-contents'),
          axios.get('/api/footer-links'),
        ]);

        // Set footer message
        const message = messageResponse.data.data[0].attributes.Message;
        setFooterMessage(message);

        // Process and set footer links
        const linksData = linksResponse.data.data;
        const groupedLinks = linksData.reduce((acc, linkItem) => {
          const { Title, text, URL } = linkItem.attributes;
          if (!acc[Title]) acc[Title] = { title: Title, links: [] };
          acc[Title].links.push({ text, href: URL });
          return acc;
        }, {});
        setLinkColumns(Object.values(groupedLinks));

      } catch (error) {
        console.error('Error fetching footer content:', error);
      }
    };

    fetchFooterContent();

  }, []);

  // Fetch social media icons
  useEffect(() => {
    const fetchSocialMediaIcons = async () => {
      try {
        const response = await axios.get('/api/social-media-links?populate=icon');
        setSocialMediaIcons(response.data.data.map(item => {
          // Accessing the URL from the nested icon attribute
          const iconUrl = item.attributes.icon.data.attributes.url;
          return {
            ...item.attributes,
            iconUrl: iconUrl ? `${process.env.REACT_APP_API_URL}${iconUrl}` : null // Prepend your backend URL if `url` is relative
          };
        }));
      } catch (error) {
        console.error('Error fetching social media links:', error);
      }
    };
  
    fetchSocialMediaIcons();
  }, []);

  return (
    <footer className="footer">
      <div className="top-row">
        <Logo />
        {linkColumns.map((column, index) => (
          <LinkColumn key={index} title={column.title} links={column.links} />
        ))}
      </div>
      <hr className="divider" />
      <div className="bottom-row">
        <div className="social-media">
         <SocialMediaIcons icons={socialMediaIcons} />
        </div>
        <p>{footerMessage}</p>
      </div>
    </footer>
  );
};

export default Footer;
