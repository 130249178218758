// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-row {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start; /* Align items at the start of the cross axis */
  width: 100%;
  padding: 0 40px; /* Adjust based on your Figma design */
}

.link-column {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

.link-column-header {
  margin-bottom: 0px;
}

.link-column-title {
  margin-bottom: 15px; /* Space between title and links */
  color: white;
  font-size: 1.2em; /* Title font size */
  /* Ensure the title doesn't wrap or overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  display: block; /* Stack links vertically */
  margin-bottom: 10px; /* Space between links */
  transition: color 0.3s ease;
}
.footer-link:hover {
  color: white; /* Change color on hover */
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/stories/components/LinkColumn/LinkColumn.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,6BAAA;EACA,uBAAA,EAAA,+CAAA;EACA,WAAA;EACA,eAAA,EAAA,sCAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAGA;EACE,mBAAA,EAAA,kCAAA;EACA,YAAA;EACA,gBAAA,EAAA,oBAAA;EACA,8CAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,+BAAA;EACA,qBAAA;EACA,cAAA,EAAA,2BAAA;EACA,mBAAA,EAAA,wBAAA;EACA,2BAAA;AAAF;AAEE;EACE,YAAA,EAAA,0BAAA;EACA,0BAAA;AAAJ","sourcesContent":[".footer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.bottom-row {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: flex-start; /* Align items at the start of the cross axis */\n  width: 100%;\n  padding: 0 40px; /* Adjust based on your Figma design */\n}\n\n.link-column {\n  display: flex;\n  flex-direction: column;\n  margin: 0 15px;\n}\n\n.link-column-header {\n  margin-bottom: 0px; // Adjust as needed for spacing between title and links\n}\n\n\n.link-column-title {\n  margin-bottom: 15px; /* Space between title and links */\n  color: white;\n  font-size: 1.2em; /* Title font size */\n  /* Ensure the title doesn't wrap or overflow */\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.footer-link {\n  color: rgba(255, 255, 255, 0.7);\n  text-decoration: none;\n  display: block; /* Stack links vertically */\n  margin-bottom: 10px; /* Space between links */\n  transition: color 0.3s ease;\n\n  &:hover {\n    color: white; /* Change color on hover */\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
