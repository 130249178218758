// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MenuItem.scss */
.menu-item {
  padding: 10px 20px;
  margin-right: 10px; /* Space between menu items */
  background-color: transparent;
  color: black; /* Adjust based on your design */
  border: none;
  cursor: pointer;
  font-size: 16px; /* Adjust based on your design */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Or 'block' depending on your design */
}
.menu-item:hover, .menu-item:focus {
  background-color: transparent; /* Adjust if you want a different background color on hover/focus */
  color: black; /* Adjust if you want a different text color on hover/focus */
  /* Add any additional hover/focus styles here */
}
.menu-item:last-child {
  margin-right: 0; /* Last menu item doesn't need right margin */
}

.menu-item-highlighted {
  background-color: black; /* Replace with the actual color from your design */
  color: white; /* Replace with the actual text color from your design */
  border-radius: 8px; /* Adjust based on your design */
}
.menu-item-highlighted:hover, .menu-item-highlighted:focus {
  /* If you want different hover/focus styles for highlighted items, adjust here */
  /* Otherwise, it inherits the hover/focus styles from .menu-item */
}`, "",{"version":3,"sources":["webpack://./src/stories/components/header/MenuItem.scss"],"names":[],"mappings":"AAAA,kBAAA;AACA;EACE,kBAAA;EACA,kBAAA,EAAA,6BAAA;EACA,6BAAA;EACA,YAAA,EAAA,gCAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA,EAAA,gCAAA;EACA,qBAAA,EAAA,qBAAA;EACA,qBAAA,EAAA,wCAAA;AACF;AACE;EACE,6BAAA,EAAA,mEAAA;EACA,YAAA,EAAA,6DAAA;EACA,+CAAA;AACJ;AAEE;EACE,eAAA,EAAA,6CAAA;AAAJ;;AAIA;EACE,uBAAA,EAAA,mDAAA;EACA,YAAA,EAAA,wDAAA;EACA,kBAAA,EAAA,gCAAA;AADF;AAGE;EACE,gFAAA;EACA,kEAAA;AADJ","sourcesContent":["/* MenuItem.scss */\n.menu-item {\n  padding: 10px 20px;\n  margin-right: 10px; /* Space between menu items */\n  background-color: transparent;\n  color: black; /* Adjust based on your design */\n  border: none;\n  cursor: pointer;\n  font-size: 16px; /* Adjust based on your design */\n  text-decoration: none; /* Remove underline */\n  display: inline-block; /* Or 'block' depending on your design */\n\n  &:hover, &:focus {\n    background-color: transparent; /* Adjust if you want a different background color on hover/focus */\n    color: black; /* Adjust if you want a different text color on hover/focus */\n    /* Add any additional hover/focus styles here */\n  }\n\n  &:last-child {\n    margin-right: 0; /* Last menu item doesn't need right margin */\n  }\n}\n\n.menu-item-highlighted {\n  background-color: black; /* Replace with the actual color from your design */\n  color: white; /* Replace with the actual text color from your design */\n  border-radius: 8px; /* Adjust based on your design */\n  \n  &:hover, &:focus {\n    /* If you want different hover/focus styles for highlighted items, adjust here */\n    /* Otherwise, it inherits the hover/focus styles from .menu-item */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
