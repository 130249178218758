// App.jsx
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'; 
import './App.scss';
import Header from './stories/components/header/Header';
import Footer from './stories/components/Footer/Footer';
import Homepage from './stories/components/homepage/homepage';
import BlogPost from './stories/components/blog/BlogPost';
import BlogList from './stories/components/blog/BlogList'; 
import About from './stories/components/about/About'; 
import Contact from './stories/components/contact/Contact';

function App() {
  useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript'; g.async = true; g.src = process.env.REACT_APP_MATOMO_URL; // Use environment variable for Matomo URL
    s.parentNode.insertBefore(g,s);
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* Define more routes as needed */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
