// ButtonItem.js
import React from 'react';
import './ButtonItem.scss'; // CSS for the ButtonItem

const ButtonItem = ({ text, highlighted = false, onClick }) => (
  <button 
    className={`button-item ${highlighted ? 'button-item-highlighted' : ''}`} 
    onClick={onClick}
  >
    {text}
  </button>
);

export default ButtonItem;
