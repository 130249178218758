// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.social-media-link {
  margin: 0 8px; /* Space between links */
  display: block; /* This makes sure the margin is applied correctly */
}

.social-media-link img {
  width: 32px; /* Figma size */
  height: 32px; /* Figma size */
}`, "",{"version":3,"sources":["webpack://./src/stories/components/SocialMediaIcons/SocialMediaIcons.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA,EAAA,wBAAA;EACA,cAAA,EAAA,oDAAA;AACF;;AAEA;EACE,WAAA,EAAA,eAAA;EACA,YAAA,EAAA,eAAA;AACF","sourcesContent":[".social-media-icons {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 10px;\n}\n\n.social-media-link {\n  margin: 0 08px; /* Space between links */\n  display: block; /* This makes sure the margin is applied correctly */\n}\n\n.social-media-link img {\n  width: 32px; /* Figma size */\n  height: 32px; /* Figma size */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
