// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-image {
  width: 100%; /* Adjust if necessary */
  height: auto;
  /* Add additional styling based on your Figma design */
}`, "",{"version":3,"sources":["webpack://./src/stories/components/titlearea/TitleImage.scss"],"names":[],"mappings":"AAAA;EACI,WAAA,EAAA,wBAAA;EACA,YAAA;EACA,sDAAA;AACJ","sourcesContent":[".title-image {\n    width: 100%; /* Adjust if necessary */\n    height: auto;\n    /* Add additional styling based on your Figma design */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
