// BlogCard.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './BlogCard.scss';

const BlogCard = ({ title, summary, imageUrl, slug }) => (
  <div className="blog-card">
    {imageUrl && <img className="blog-card-image" src={imageUrl} alt={`Cover for ${title}`} />}
    <h3 className="blog-card-title">{title}</h3>
    <p className="blog-card-summary">{summary}</p>
    <Link to={`/blog/${slug}`} className="blog-card-link">Read More</Link>
  </div>
);

export default BlogCard;
