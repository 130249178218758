import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../logo/Logo';
import MenuItem from './MenuItem';
import axios from '../../../services/axiosConfig';
import './Header.scss';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [socialMediaIcons, setSocialMediaIcons] = useState([]);
  const [logoData, setLogoData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch menu items
        const menuResponse = await axios.get('/api/menus');
        setMenuItems(menuResponse.data.data);

        // Fetch social media links
        const socialResponse = await axios.get('/api/social-media-links?populate=icon');
        // Update based on the new structure provided in the example
        setSocialMediaIcons(socialResponse.data.data.map(item => ({
          ...item.attributes,
          iconUrl: item.attributes.icon.data.attributes.url, // Directly use the full URL
        })));

        // Fetch header contents which includes logo data
        const headerContentResponse = await axios.get('/api/header-contents');
        if (headerContentResponse.data.data.length > 0) {
          setLogoData(headerContentResponse.data.data[0].attributes);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  // Dynamically returns the social media icons and links items
  const renderSocialMediaLinks = () => {
    return socialMediaIcons.map((icon, index) => (
      <a key={index} href={icon.url} target="_blank" rel="noopener noreferrer">
        <img src={icon.iconUrl} alt={icon.Name} className="social-icon" />
      </a>
    ));
  };

  // Dynamically generate menu items
  const renderMenuItems = () => {
    // Sort the menu items by their 'order' attribute before mapping
    const sortedMenuItems = [...menuItems].sort((a, b) => a.attributes.order - b.attributes.order);

    return sortedMenuItems.map((item) => (
      <MenuItem
        key={item.id}
        text={item.attributes.name}
        path={item.attributes.path}
        highlighted={item.attributes.highlighted}
        onClick={closeMenu}
      />
    ));
  };

  return (
    <header className="header">
      <Logo siteName={logoData.siteName} logoImage={logoData.logoImage} logoText={logoData.logoText} />
      <FaBars className={`hamburger ${isMenuOpen ? 'is-active' : ''}`} onClick={toggleMenu} />
      <nav className={isMenuOpen ? "nav-active" : ""} style={{ paddingTop: isMenuOpen ? '80px' : '0' }}> {/* This inline style will add padding only when the menu is open */}
        <FaTimes className="close-menu" onClick={closeMenu} />
        {renderMenuItems()}
        {isMenuOpen && <div className="social-media-footer">{renderSocialMediaLinks()}</div>}
      </nav>
    </header>
  );
};

export default Header;
