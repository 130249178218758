// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtitle {
  font-size: 1.5em; /* Adjust to match your Figma design */
  margin-bottom: 1em;
  width: 100%; /* Adjust if necessary */
  /* Add additional styling based on your Figma design */
}`, "",{"version":3,"sources":["webpack://./src/stories/components/titlearea/Subtitle.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA,EAAA,sCAAA;EACA,kBAAA;EACA,WAAA,EAAA,wBAAA;EACA,sDAAA;AACJ","sourcesContent":[".subtitle {\n    font-size: 1.5em; /* Adjust to match your Figma design */\n    margin-bottom: 1em;\n    width: 100%; /* Adjust if necessary */\n    /* Add additional styling based on your Figma design */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
