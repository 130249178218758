import React from 'react';
import './LinkColumn.scss';

const LinkColumn = ({ title, links }) => {
  return (
    <div className="link-column">
      <div className="link-column-header">
        <h3 className="link-column-title">{title}</h3>
      </div>
      <div className="link-column-links">
        {links.map((link, index) => (
          <a key={index} href={link.href} className="footer-link">
            {link.text}
          </a>
        ))}
      </div>
    </div>
  );
};


export default LinkColumn;
