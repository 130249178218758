// BlogCardContainer.jsx
import React from 'react';
import BlogCard from './BlogCard';
import './BlogCardContainer.scss';

const BlogCardContainer = ({ posts, limit }) => { // Add the 'limit' prop
  const extractSummary = (content) => {
    const firstParagraph = content.find(block => block.type === 'paragraph');
    return firstParagraph?.children.map(child => child.text).join(' ') || 'No summary available';
  };

  const buildImageUrl = (imagesData) => {
    if (imagesData && imagesData[0] && imagesData[0].attributes && imagesData[0].attributes.url) {
      return imagesData[0].attributes.url; 
    }
    return 'default-image-path'; // Replace with the path to a default image
  };

  const displayedPosts = typeof limit === 'number' ? posts.slice(0, limit) : posts; // Use the 'limit' to slice the posts array

  return (
    <div className="blog-card-area">
      <h2 className="blog-posts-title">Blog Posts</h2>
      <div className="blog-card-container">
        {displayedPosts.map(post => { // Use the 'displayedPosts' to control the number of posts shown
          const { id, attributes } = post;
          const { Title, Content, slug, Images } = attributes;
          const summary = extractSummary(Content);
          const imageUrl = buildImageUrl(Images.data); // Use the function to get the image URL

          return (
            <BlogCard 
              key={id}
              title={Title}
              summary={summary}
              imageUrl={imageUrl}
              slug={slug || `post-${id}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BlogCardContainer;
