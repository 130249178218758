// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.scss */
.logo {
  /* Your styles for logo */
}

.menu-item {
  /* Your styles for menu item */
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
  /* default state properties */
  background-color: #FFFFFF;
  color: #000000;
  /* other properties */
}

.logo-text-link {
  color: black;
  text-decoration: none;
}
.logo-text-link:visited, .logo-text-link:link, .logo-text-link:active {
  color: black;
}
.logo-text-link:hover {
  color: black;
}

.menu-item:hover {
  /* hover state properties */
  background-color: #F0F0F0;
  color: #000000;
  /* other properties that change on hover */
}

.header {
  /* Your styles for header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* Add styles for navigation */
nav {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/stories/styles.scss"],"names":[],"mappings":"AAAA,gBAAA;AACA;EACI,yBAAA;AACJ;;AAEE;EACE,8BAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACG,6BAAA;EACA,yBAAA;EACA,cAAA;EACA,qBAAA;AACP;;AAEE;EACE,YAAA;EACA,qBAAA;AACJ;AACI;EACE,YAAA;AACN;AAEI;EACE,YAAA;AAAN;;AAIE;EACE,2BAAA;EACA,yBAAA;EACA,cAAA;EACA,0CAAA;AADJ;;AAKE;EACE,2BAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;AAFJ;;AAKE,8BAAA;AACA;EACE,aAAA;AAFJ","sourcesContent":["/* styles.scss */\n.logo {\n    /* Your styles for logo */\n  }\n  \n  .menu-item {\n    /* Your styles for menu item */\n    margin: 0 10px;\n    padding: 5px 10px;\n    cursor: pointer;\n       /* default state properties */\n       background-color: #FFFFFF;\n       color: #000000;\n       /* other properties */\n  }\n  \n  .logo-text-link {\n    color: black; // Ensures the link is always black\n    text-decoration: none; // Removes underline from link\n    \n    &:visited, &:link, &:active {\n      color: black; // Keeps the link black regardless of its state\n    }\n  \n    &:hover {\n      color: black; // Optionally, you can change the hover color if desired\n    }\n  }\n\n  .menu-item:hover {\n    /* hover state properties */\n    background-color: #F0F0F0;\n    color: #000000;\n    /* other properties that change on hover */\n  }\n  \n  \n  .header {\n    /* Your styles for header */\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n  }\n  \n  /* Add styles for navigation */\n  nav {\n    display: flex;\n  }\n  \n \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
