// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-card-area {
  background-color: #f0f0f0;
  padding: 20px;
}

.blog-posts-title {
  margin-bottom: 20px;
  text-align: left;
}

.blog-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/stories/components/blog/BlogCardContainer.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,aAAA;AADF;;AAIE;EAEE,mBAAA;EACA,gBAAA;AAFJ;;AAME;EACE,aAAA;EACA,eAAA;EACA,SAAA;AAHJ","sourcesContent":["// BlogCardContainer.scss\n\n.blog-card-area {\n  background-color: #f0f0f0; // Grey background for the entire blog area\n  padding: 20px; // Adjust padding as needed to match Figma design\n  //margin: 20px 0; // Add margin if necessary to match design\n}\n  .blog-posts-title {\n    // Add styles for the \"Blog Posts\" title\n    margin-bottom: 20px; // Space between title and cards\n    text-align: left;\n    // Add other styles as per your Figma design\n  }\n\n  .blog-card-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px; // Adjust the gap between cards as per your design\n  // No need for justify-content here if you want cards to start from the left\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
