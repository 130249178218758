import axios from 'axios';

// Use environment variable for baseURL. Fallback to a default URL if not set.
const baseURL = process.env.REACT_APP_API_URL || 'https://api.jorgeiglesias.com';

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you need
  },
});

export default axiosInstance;
