// Homepage.jsx
import React, { useEffect, useState } from 'react';
import BlogCardContainer from '../blog/BlogCardContainer.jsx';
import TitleAreaContainer from '../titlearea/TitleAreaContainer.jsx';
import axios from '../../../services/axiosConfig';
import '../homepage/homepage.scss';

const Homepage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('/api/blog-articles?populate=Images&_limit=6');
        const formattedPosts = response.data.data.map((post) => {
          // Ensure at least one image exists, else set a fallback URL or null
          const imageUrl = post.attributes.Images.data.length > 0
            ? post.attributes.Images.data[0].attributes.url
            : 'path/to/default/image.jpg'; // Fallback image URL

          return {
            ...post,
            imageUrl, // Add imageUrl directly to the post object for easier access
          };
        });

        setPosts(formattedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div>
      <TitleAreaContainer />
      <section className="blog-posts-section">
        <BlogCardContainer posts={posts} limit={6} />
      </section>
    </div>
  );
};

export default Homepage;
