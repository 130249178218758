// TitleAreaContainer.js
import React, { useState, useEffect } from 'react';
import axios from '../../../services/axiosConfig';
import TitleArea from './TitleArea';

const TitleAreaContainer = () => {
  const [titleAreaData, setTitleAreaData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/title-areas?populate=TitleImage');
        if (response.data.data.length > 0) {
          const { attributes } = response.data.data[0];
          // Check if the TitleImage object and url exists before attempting to set it
          const titleImageUrl = attributes.TitleImage && attributes.TitleImage.data && attributes.TitleImage.data.attributes.url
            ? attributes.TitleImage.data.attributes.url // Use direct URL from Strapi
            : null;

          
          setTitleAreaData({
            title: attributes.Title,
            subtitle: attributes.Subtitle,
            // Use the titleImageUrl variable which now holds the correct path
            titleImageUrl: titleImageUrl,
            buttonText: attributes.buttonText,
            buttonLink: attributes.buttonLink
          });
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  if (!titleAreaData) {
    return <div>Loading...</div>;
  }

  return (
    <TitleArea
      title={titleAreaData.title}
      subtitle={titleAreaData.subtitle}
      imageUrl={titleAreaData.titleImageUrl}
      buttonText={titleAreaData.buttonText}
      buttonLink={titleAreaData.buttonLink}
    />
  );
};

export default TitleAreaContainer;
